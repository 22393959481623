import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Flex } from "theme-ui"

import Layout from "../../components/layouts"
import { TileImage } from "../../components/Page/ContentModules/Tile/elements"
import { useTranslationContext } from "../../context/TranslationContext"
import ContentModules from "../../components/Page/ContentModules"
import { NewsletterInput } from "../../components/new-ui/newsletter-signup"

const canonicals = {
  ["en-US"]: "/subscribe",
  ["de-DE"]: "/de/subscribe",
}

const Subscribe = ({ data }) => {
  const { setTranslationContext } = useTranslationContext()

  const { tileSection } = data

  useEffect(() => {
    setTranslationContext({
      locale: "de-DE",
      canonicals: canonicals,
    })
  }, [setTranslationContext])

  return (
    <Layout>
      {tileSection?.tiles && (
        <Flex
          sx={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            marginX: ["1em"],
            flexDirection: ["column", "row"],
          }}
        >
          {tileSection.tiles?.[0]?.backgroundImage && (
            <Flex sx={{ flex: 1, width: ["100%", "initial"] }}>
              <TileImage
                image={tileSection.tiles[0].backgroundImage.gatsbyImageData}
                alt=""
              />
            </Flex>
          )}
          <Flex
            sx={{
              flexDirection: "column",
              flex: 1,
              alignItems: "center",
              marginTop: ["32px", "0px"],
              paddingX: ["0px", "8px"],
            }}
          >
            <Flex
              sx={{
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "450px",
                minHeight: "300px",
              }}
            >
              <NewsletterInput showDescription={true} />
            </Flex>
          </Flex>
        </Flex>
      )}
    </Layout>
  )
}

export default Subscribe

export const query = graphql`
  {
    tileSection: contentfulTileSection(
      title: { eq: "subscribe-tile-section" }
      node_locale: { eq: "de-DE" }
    ) {
      title
      tiles {
        ...Tile
        ...CollectionProduct
      }
      internal {
        type
      }
    }
  }
`
